import React from "react";
import { graphql } from "gatsby";
// import Blog from "./blog";
import BlogElem from "../components/blog/blog";
import Content from "../components/utilities/content";
import CmsImage from "../components/utilities/cms-image";
import HeadingTag from "../components/utilities/heading-tag";
import Seo from "../components/global/seo";
import IconBack from "../images/icon-back.svg";
import Link from "../components/utilities/link";

const Post = ({
  data: {
    wpPost: post,
    wpPage: page,
    wp: {
      themeSettings: { themeSettings },
    },
  },
}) => {
  return (
    <>
      <Seo
        title={post.page.metaTitle}
        description={post.page.metaDescription}
        themeSettings={themeSettings}
      />
      <BlogElem themeSettings={themeSettings}>
        <div className="tab-back h1 post-back">
          <Link to={page.uri}>
            <img src={IconBack} alt="Back" />
            Back
          </Link>
        </div>
        <CmsImage
          image={post.featuredImage ? post.featuredImage.node : null}
          className="p-3"
        />
        <HeadingTag type={post.blog.titleTag} className="post-title">
          {post.title}
        </HeadingTag>

        {post.blog.subTitle && (
          <HeadingTag
            type={post.blog.subTitleTag ?? "h2"}
            className="post-subtitle"
          >
            {post.blog.subTitle}
          </HeadingTag>
        )}

        <Content className="content-blog">{post.content}</Content>
      </BlogElem>
    </>
  );
};

export const pageQuery = graphql`
  query Post($id: String!, $blogId: String!) {
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          headingSub {
            fieldGroupName
            title
            titleTag
            color
            fontSize
          }
          headingTextStroke {
            fieldGroupName
            title
            titleTag
            color
            fontSize
          }
        }
      }
    }
    wpPost(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
      }
      page {
        fieldGroupName
        metaTitle
        metaDescription
      }
      blog {
        fieldGroupName
        titleTag
        subTitle
        subTitleTag
      }
    }
    wpPage(id: { eq: $blogId }) {
      uri
    }
  }
`;

export default Post;
